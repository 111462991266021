<template>
	<div class="user-page__wrapper">
		<div class="user-page">
			<UserMenu />
			<div class="user-page__content certificates">
				<h2>{{ title }}</h2>
				<VueMarkdown v-if="description" :source="description" class="certificates-description content" :prerender="prerender" />

				<div v-if="surveyRequired && bannersVisible" class="certificates-bannerwrapper">
					<template v-for="banner in banners_before">
						<a v-if="banner.link[0].url && banner.title" class="certificates-banner" :href="fixUrl(banner.link[0].url)" target="_blank">
							<img :src="banner.icon | imageUrl" />
							<span>{{ banner.title }}</span>
						</a>
					</template>
				</div>

				<div v-if="surveyRequired && !denied && bannersVisible">
					<div class="certificates-refresh">
						<inline-svg :src="require('../assets/refresh.svg')" width="25" />
						<span>If you cannot view the download buttons, please refresh the webpage as soon you have submitted the survey</span>
					</div>
				</div>

				<div v-if="attendance || cme" class="certificates-bannerwrapper">
					<template v-if="attendance">
						<a v-if="attendance.link[0].url && attendance.title" class="certificates-banner" @click.prevent="download(attendance)">
							<img v-if="!attendance.loading" :src="attendance.icon | imageUrl" />
							<div v-if="attendance.loading" class="loader is-loading"></div>
							<span>{{ attendance.title }}</span>
						</a>
					</template>
					<template v-if="cme">
						<a v-if="cme.link[0].url && cme.title" class="certificates-banner" @click.prevent="download(cme)">
							<img v-if="!cme.loading" :src="cme.icon | imageUrl" />
							<div v-if="cme.loading" class="loader is-loading"></div>
							<span>{{ cme.title }}</span>
						</a>
					</template>
				</div>

				<p v-if="denied" class="certificate-description">
					Sorry, you are not allowed to access this page. For any questions please contact us at <a href="mailto:support@eaaci.org">support@eaaci.org</a>.
				</p>
			</div>
		</div>
	</div>
</template>

<script>
	import UserMenu from '../components/UserMenu';
	import $http from '../utils/http';
	import session from '../utils/session';
	import platformSettings from "../utils/platformSettings";
	import moment from "moment-timezone";
	import VueMarkdown from 'vue-markdown-v2';
	import { prerender } from '../utils/markdown';

	export default {
		name: 'Certificates',
		components: { UserMenu, VueMarkdown },
		data() {
			return {
				denied: true,
				title: 'Attendance & CME Certificates',
				description: null,
				banners_after: [],
				banners_before: [],
				accessAttendance: false,
				accessCme: false,
				session: session.state,
				platformSettings: platformSettings.state,
			};
		},
		beforeRouteEnter(to, from, next) {
			$http
				.all([$http.get('certificate-download'), $http.get('certificate-access')])
				.then(
					$http.spread((page, acc) => {
						next(vm => {
							vm.setPageData(page);
							vm.setUserInfo(acc);
						});
					})
				)
				.catch(() => {
					next();
				});
		},

		beforeRouteUpdate(to, from, next) {
			$http
				.all([$http.get('certificate-download'), $http.get('certificate-access')])
				.then(
					$http.spread((page, acc) => {
						this.setPageData(page);
						this.setUserInfo(acc);
						next();
					})
				)
				.catch(() => {
					next();
				});
		},
		computed: {
			bannersVisible() {
				let now = moment().tz('CET');
				let cgEnd = moment(this.platformSettings.congressEnd);
				return now.isAfter(cgEnd);
			},
			surveyRequired() {
				return this.accessAttendance === 'survey_required' || this.accessCme === 'survey_required';
			},
			attendance() {
				if (this.accessAttendance === 'allowed' && this.banners_after) {
					return this.banners_after.find(e => e.link[0].url.includes('attendance=true'));
				}
				return false;
			},
			cme() {
				if (this.accessCme === 'allowed' && this.banners_after) {
					return this.banners_after.find(e => e.link[0].url.includes('cme=true'));
				}
				return false;
			}
		},
		methods: {
			setPageData(data) {
				this.denied = false;
				this.title = data.data.title;
				this.description = data.data.description;
				this.banners_after = data.data.banners_after;
				this.banners_before = data.data.banners_before;
			},
			setUserInfo(acc) {
				if (acc.data) {
					this.accessAttendance = acc.data.attendanceCertificate;
					this.accessCme = acc.data.cmeCertificate;
				} else {
					this.accessAttendance = false;
					this.accessCme = false;
				}
			},
			fixUrl(url) {
				return url.replace('{username}', this.session.user.username);
			},
			download(banner) {
				let url = banner.link[0].url;
				banner.loading = true;
				this.$set(this.banners_after, this.banners_after.indexOf(banner), banner);
				$http({
					url,
					method: 'GET',
					responseType: 'blob',
				}).then(response => {
					const blob = new Blob([response.data], {
						type: response.headers['content-type'],
					});
					const uri = window.URL.createObjectURL(blob);
					const link = document.createElement('a');
					link.href = uri;
					if (url.indexOf('cme') !== -1) {
						link.setAttribute('download', 'ISAF2023_CME-Accreditation-Certificate.pdf');
					} else {
						link.setAttribute('download', 'ISAF2023_Certificate-of-Attendance.pdf');
					}
					document.body.appendChild(link);
					link.click();
					banner.loading = false;
					this.$set(this.banners_after, this.banners_after.indexOf(banner), banner);
					window.URL.revokeObjectURL(url);
				});
			},
			prerender: prerender,
		},
	};
</script>

<style scoped></style>
